import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors, typography, size, roundBorderLight, roundBorderMedium, roundBorderDark } from '../../styles';

// Why is this here?
export const LabelWrapper = styled.label<{
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
  deluxe?: boolean;
  checked?: boolean;
}>`
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  gap: 12px;
  position: relative;

  & > div {
    order: ${(p) => (p.labelPlacement === 'start' ? '1' : '0')};
  }

  & > span {
    ${typography.body}
    color: ${(p) => (p.disabled ? colors.navy[300] : colors.navy[1000])};
  }

  ${({ deluxe, checked }) =>
    deluxe &&
    css`
      padding: ${size(1.5)};
      justify-content: space-between;
      ${checked ? roundBorderDark : roundBorderLight}

      &:hover {
        ${roundBorderMedium}
      }
    `}
`;
