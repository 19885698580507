import styled from '@emotion/styled';

import { colors, size, roundBorderLight, roundBorderMedium, roundBorderDark, typography } from '../../../styles';
import { LabelWrapper } from '../LabelWrapper';

export enum DisplayVariant {
  grid = 'grid',
  flex = 'flex',
}

export const Fieldset = styled.fieldset<{
  maxHeight?: string | number;
  display: DisplayVariant;
  columns: number;
  gap: string;
}>`
  border: none;
  width: 100%;
  padding: unset;
  margin: unset;
  gap: ${({ gap }) => gap};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ display, columns }) =>
    display === DisplayVariant.grid
      ? `
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
  `
      : `
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  `}

  & > legend {
    padding-bottom: ${size(1)};
  }
`;

export const StyledLabelWrapper = styled(LabelWrapper)<{ checked?: boolean; isInline?: boolean }>`
  ${({ checked, isInline }) => (checked && !isInline ? roundBorderDark : roundBorderLight)}

  &:hover {
    ${roundBorderMedium}
  }

  padding: ${size(2)};
  justify-content: center;

  ${({ isInline }) =>
    isInline &&
    `
    border-radius: 0;

    &:hover {
      background-color: ${colors.background.active};
      border-color: ${colors.background.active};
      border-radius: 0;
    }

    &:last-of-type {
      border-radius: 0 ${size(1.5)} ${size(1.5)} 0;
    }

    &:first-of-type {
      border-radius: ${size(1.5)} 0 0 ${size(1.5)};
    }

    &:only-of-type {
      border-radius: ${size(1.5)};
    }
  `}

  ${({ checked, isInline }) =>
    checked &&
    isInline &&
    `
      border-radius: 0;
      background-color: ${colors.background.active};
      border-color: ${colors.background.active};

    `}
`;

export const StyledLabel = styled.div<{ checked?: boolean }>`
  ${({ checked }) => (checked ? typography.bodyBold : typography.body)}
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

export const PillInner = styled.input`
  display: none;
`;
