import React, { useState, useRef, useEffect } from 'react';

import styled from '@emotion/styled';

import { size } from '../../styles/size';
import { IconButton } from '../Button';

enum CarouselDirection {
  Next = 'next',
  Prev = 'prev',
}

export const Carousel = ({ children, itemWidth = 300 }: { children: React.ReactNode; itemWidth?: number }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const updateButtonsVisibility = () => {
    if (!scrollRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShowPrev(scrollLeft > 0);
    setShowNext(scrollLeft + clientWidth < scrollWidth);
  };

  useEffect(() => {
    updateButtonsVisibility();

    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', updateButtonsVisibility);
      window.addEventListener('resize', updateButtonsVisibility);

      // Add wheel event handler to prevent browser back/forward navigation
      const handleWheel = (e: WheelEvent) => {
        if (Math.abs(e.deltaX) > Math.abs(e.deltaY) || e.shiftKey) {
          const { scrollLeft, scrollWidth, clientWidth } = container;
          if ((scrollLeft <= 0 && e.deltaX < 0) || (scrollLeft + clientWidth >= scrollWidth && e.deltaX > 0)) {
            e.preventDefault();
          }
        }
      };
      container.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        container.removeEventListener('scroll', updateButtonsVisibility);
        window.removeEventListener('resize', updateButtonsVisibility);
        container.removeEventListener('wheel', handleWheel);
      };
    }
  }, [children]);

  const scrollCards = (direction: CarouselDirection) => {
    if (!scrollRef.current) return;
    const container = scrollRef.current;
    const { clientWidth, scrollLeft } = container;

    const cardElements = Array.from(container.children);
    if (!cardElements.length) return;
    const firstCard = cardElements[0];
    if (!(firstCard instanceof HTMLElement)) return;
    const cardWidth = firstCard.offsetWidth;
    const gap = parseInt(window.getComputedStyle(container).columnGap || '0');
    const cardWidthWithGap = cardWidth + gap;

    const currentFirstVisibleIndex = Math.floor(scrollLeft / cardWidthWithGap);
    const lastVisiblePixel = scrollLeft + clientWidth;
    const lastVisibleCardIndex = Math.min(Math.ceil(lastVisiblePixel / cardWidthWithGap) - 1, cardElements.length - 1);
    const isLastCardFullyVisible = (lastVisibleCardIndex + 1) * cardWidthWithGap - gap <= lastVisiblePixel;

    let targetScrollPosition;

    if (direction === CarouselDirection.Next) {
      if (!isLastCardFullyVisible) {
        targetScrollPosition = lastVisibleCardIndex * cardWidthWithGap;
      } else {
        targetScrollPosition = (lastVisibleCardIndex + 1) * cardWidthWithGap;
      }
    } else {
      const visibleCardsCount = Math.floor(clientWidth / cardWidthWithGap);
      let newFirstVisibleIndex = currentFirstVisibleIndex - visibleCardsCount;
      newFirstVisibleIndex = Math.max(0, newFirstVisibleIndex);
      targetScrollPosition = newFirstVisibleIndex * cardWidthWithGap;
    }

    container.scrollTo({
      left: targetScrollPosition,
      behavior: 'smooth',
    });
  };

  const handleNextClick = () => {
    scrollCards(CarouselDirection.Next);
  };

  const handlePrevClick = () => {
    scrollCards(CarouselDirection.Prev);
  };

  return (
    <CardsRowWrapper>
      {showPrev && <PrevButton aria-label="Previous" icon="arrow-left" variant="secondary" onClick={handlePrevClick} />}
      {showNext && <NextButton aria-label="Next" icon="arrow-right" variant="secondary" onClick={handleNextClick} />}
      <CardsRow ref={scrollRef} itemWidth={itemWidth}>
        {children}
      </CardsRow>
    </CardsRowWrapper>
  );
};

const CardsRow = styled.div<{ itemWidth: number }>`
  display: flex;
  gap: ${size(2)};
  overflow-x: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 ${(props) => props.itemWidth}px;
    width: ${(props) => props.itemWidth}px;
  }
`;

const CardsRowWrapper = styled.div`
  position: relative;
`;

const NextButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
`;

const PrevButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
`;
