/*
  This dropdown is not a part of Twig. It is just a stripped down version of PopupMenu with sublabel functionality added,
  it is solely here temporarily for convenience. We should refactor/rebuild this with typescript when time allows
  https://sprig-inc.atlassian.net/browse/STUDIES-7143
*/
/* eslint-disable */
// @ts-nocheck

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { size, colors, typography } from '../../styles';
import { Icon } from '../Icon';
import { Popup } from '../Popup';
export const PopupMenu = ({
  children,
  header,
  options = [],
  on = 'hover',
  placement = 'right',
  onSelect,
  className,
  optionsMaxHeight,
  forceOpen = false,
}) => (
  <StyledPopup
    on={on}
    placement={placement}
    trigger={children}
    className={className}
    wrapperStyle={{ display: 'flex' }}
    triggerContainerStyle={{ flex: '1' }}
    arrow={false}
    forceOpen={forceOpen}
    preventDefault
    stopPropagation
  >
    {({ onClose }) => (
      <>
        {header && <MenuHeader>{header}</MenuHeader>}
        <Options
          onClick={(options) => (event) => onSelect(options, event, onClose)}
          options={options}
          optionsMaxHeight={optionsMaxHeight}
        />
      </>
    )}
  </StyledPopup>
);

PopupMenu.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  header: PropTypes.string,
  optionsMaxHeight: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      sublabel: PropTypes.string,
      active: PropTypes.bool,
      disabledTooltip: PropTypes.string,
      icon: PropTypes.string,
      showDividerBefore: PropTypes.bool,
    })
  ),
  forceOpen: PropTypes.bool,
};

const disabledOnClick = (e) => {
  e.stopPropagation();
};

export const Options = ({ options, onClick, optionsMaxHeight }) => (
  <MenuContainer role="menu" optionsMaxHeight={optionsMaxHeight}>
    {options.map(({ value, active, label, disabled, style, sublabel, disabledTooltip, icon, showDividerBefore }) => {
      const boundOnClick = disabled ? disabledOnClick : onClick({ value, active, label, disabled, style, sublabel });
      const iconComponent = typeof icon === 'string' ? <Icon size={3} src={icon} /> : icon;
      return (
        <Option
          onClick={boundOnClick}
          key={value}
          active={active}
          role="menuitem"
          disabled={disabled}
          style={{ ...style }}
          aria-label={label}
          showTopBorder={showDividerBefore}
        >
          <OptionContainer>
            {icon && iconComponent}
            <OptionTextContainer>
              {label}
              {SubLabel && <SubLabel>{sublabel}</SubLabel>}
            </OptionTextContainer>
            {disabledTooltip && (
              <Popup
                contentContainerStyle={{ width: '310px', fontWeight: 400 }}
                trigger={<DisabledTooltiopIcon src="info" />}
                triggerContainerStyle={{ display: 'flex' }}
              >
                <DisabledTooltip>{disabledTooltip}</DisabledTooltip>
              </Popup>
            )}
          </OptionContainer>
        </Option>
      );
    })}
  </MenuContainer>
);

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      sublabel: PropTypes.string,
      active: PropTypes.bool,
      icon: PropTypes.string,
      showDividerBefore: PropTypes.bool,
    })
  ),
  onClick: PropTypes.func,
  optionsMaxHeight: PropTypes.string,
};

const MenuContainer = styled.div`
  ${({ optionsMaxHeight }) =>
    optionsMaxHeight &&
    `
    overflow-y: auto;
    max-height: ${optionsMaxHeight};
  `}
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${size(2)};
`;

const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const DisabledTooltip = styled.p`
  color: ${colors.common.black};
`;

const DisabledTooltiopIcon = styled(Icon)`
  color: ${colors.common.black};
`;

const OptionIcon = styled(Icon)`
  border-radius: ${size(0.5)};
`;

export const StyledPopup = styled(Popup)`
  min-width: ${(p) => (p.minWidth ? p.minWidth : '200px')};
  padding: 5px 0;
`;

export const SubLabel = styled.div`
  ${typography.smallBody}
  background: inherit;
  padding: 0;
`;

export const MenuHeader = styled.span`
  padding: ${size(1)} ${size(2)};
  color: ${colors.navy[700]};
  ${typography.caption}
`;

const Option = styled.div`
  ${typography.bodyMedium}
  ${({ active }) => active && `font-weight: bold;`}
  ${({ showTopBorder }) => showTopBorder && `border-top: 1px solid ${colors.navy[50]};`}
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: ${size(1)} ${size(2)};

  &:hover {
    background-color: ${colors.cream[600]};
  }

  ${({ disabled }) => disabled && `color: ${colors.navy[400]};`}
`;
