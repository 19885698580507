import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import Color from 'color';

import { elevation, colors } from '../../../styles';
import { SizeVariant } from '../../../utils';
import { getInputSize, getInputSizeInner } from '../getInputSize';
import { LabelWrapper } from '../LabelWrapper';
import { Variant } from '../types';

export interface RadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  className?: string;
  label?: React.ReactNode;
  labelPlacement?: 'start' | 'end';
  variant?: Variant;
  size?: SizeVariant;
}

const RadioInner = (
  { className, label, checked, labelPlacement = 'end', size, variant = Variant.regular, ...props }: RadioProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const disabled = props.disabled;
  const deluxe = variant === Variant.deluxe;
  const inputSize = size ? size : deluxe ? SizeVariant.large : SizeVariant.medium;

  return (
    <LabelWrapper
      checked={checked}
      deluxe={deluxe}
      className={className}
      disabled={disabled}
      labelPlacement={labelPlacement}
    >
      <Wrapper size={inputSize}>
        <StyledInput {...props} checked={checked} disabled={disabled} ref={ref} type="radio" />
        <Check size={inputSize} />
      </Wrapper>
      {label && <span>{label}</span>}
    </LabelWrapper>
  );
};

export const Radio = forwardRef(RadioInner);

const Wrapper = styled.div<{ size: SizeVariant }>`
  display: flex;
  flex-shrink: 0;
  position: relative;
  height: ${({ size }) => getInputSize(size)};
  width: ${({ size }) => getInputSize(size)};
`;

const StyledInput = styled.input`
  cursor: inherit;
  height: inherit;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: inherit;
  z-index: 1;

  &:checked {
    & + div {
      background-color: ${colors.gold[800]};
      border-color: ${colors.gold[1000]};
      &:after {
        background-color: ${colors.navy[1000]};
        display: block;
      }
    }
  }

  &:active {
    & + div {
      background-color: ${colors.navy[100]};
      &:after {
        display: block;
      }
    }
    &:checked + div {
      background-color: ${colors.gold[1000]};
    }
  }
  &:disabled {
    & + div {
      background-color: ${colors.navy[50]};
      border-color: ${colors.navy[100]};
      box-shadow: none;
    }
    &:checked + div {
      background-color: ${colors.gold[300]};
      border-color: ${colors.gold[300]};
      &:after {
        background-color: ${colors.navy[300]};
      }
    }
  }
  &:focus-visible {
    & + div {
      border-color: ${colors.navy[1000]};
      box-shadow: 0px 0px 0px 2px ${Color(colors.navy[1000]).alpha(0.3).string()};
      &:after {
        display: block;
      }
    }
    &:checked + div {
      border-color: ${colors.gold[900]};
    }
  }
  /* Label affects the hover state so we would need to check for other states */
  &:hover:not(:active):not(:disabled):not(:focus-visible) {
    & + div {
      background-color: ${colors.navy[50]};

      &:after {
        background-color: ${colors.navy[100]};
        display: block;
      }
    }
    &:checked + div {
      background-color: ${colors.gold[900]};
      &:after {
        background-color: ${colors.navy[1000]};
      }
    }
  }
`;

const Check = styled.div<{ size: SizeVariant }>`
  border: 1px solid ${colors.navy[300]};
  border-radius: 50%;
  bottom: 0px;
  box-shadow: ${elevation.base1};
  box-sizing: border-box;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;

  &:after {
    background-color: ${colors.navy[100]};
    border-radius: 50%;
    bottom: 0;
    content: '';
    display: none;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: ${({ size }) => getInputSizeInner(size)};
    height: ${({ size }) => getInputSizeInner(size)};
  }
`;
