/**
 * use this, IOSReplaySettings below will be deprecated once all customers have migrated off of the current versions
 */
export enum IOSMaskingLevel {
  Basic = 'basic',
  Full = 'full',
}

export interface IOSReplaySettings {
  hideIsSecureTextEntry: boolean;
  hideSpecificClasses: string | null;
  showSpecificClasses: string | null;
  maskingLevel?: IOSMaskingLevel;
  privacyLevel: PrivacySettings;
}

export interface AndroidReplaySettings {
  hideEditText: boolean;
  hideImageView: boolean;
  hideMapView: boolean;
  hideNumericPassword: boolean;
  hideSpecificClasses: string | null;
  showSpecificClasses: string | null;
  hideTextView: boolean;
  privacyLevel: PrivacySettings;
}

export interface WebReplaySettings {
  hideAllFormContents: boolean;
  hidePasswordsOnly: boolean;
  hideAllImages: boolean;
  hideCssSelectors: string | null;
  privacyLevel: PrivacySettings;
  showCssSelectors?: string;
}

export interface ReplaySettingsPlatform {
  ios?: IOSReplaySettings;
  android?: AndroidReplaySettings;
  web?: WebReplaySettings;
}

export const ANDROID_DEFAULT_CLASSES: Record<
  Exclude<
    keyof AndroidReplaySettings,
    'hideSpecificClasses' | 'hideNumericPassword' | 'privacyLevel' | 'showSpecificClasses'
  >,
  string
> = {
  hideEditText: 'EditText',
  hideTextView: 'TextView',
  hideMapView: 'MapView',
  hideImageView: 'ImageView',
};

export enum PrivacySettings {
  Basic = 'basic',
  Moderate = 'moderate',
  Strict = 'strict',
}

export const ANDROID_REPLAY_DEFAULTS: AndroidReplaySettings = {
  hideEditText: true,
  hideImageView: false,
  hideMapView: false,
  hideNumericPassword: true,
  hideSpecificClasses: null,
  showSpecificClasses: null,
  hideTextView: true,
  privacyLevel: PrivacySettings.Moderate,
};

export const IOS_DEFAULT_CLASSES: Record<
  Exclude<
    keyof IOSReplaySettings,
    'hideIsSecureTextEntry' | 'hideSpecificClasses' | 'maskingLevel' | 'privacyLevel' | 'showSpecificClasses'
  >,
  string
> = {
  hideUITextField: 'UITextField',
  hideUITextView: 'UITextView',
  hideWKWebView: 'WKWebView',
  hideUIInputView: 'UIInputView',
  hideMKMapView: 'MKMapView',
  hideUIImageView: 'UIImageView',
  hideUILabel: 'UILabel',
};

export const IOS_REPLAY_DEFAULTS: IOSReplaySettings = {
  hideIsSecureTextEntry: true,
  hideSpecificClasses: null,
  showSpecificClasses: null,
  maskingLevel: IOSMaskingLevel.Basic,
  privacyLevel: PrivacySettings.Moderate,
};

export const WEB_REPLAY_DEFAULTS: WebReplaySettings = {
  hideAllFormContents: true,
  hidePasswordsOnly: false,
  hideAllImages: false,
  hideCssSelectors: null,
  privacyLevel: PrivacySettings.Moderate,
};

export enum ReplayEventType {
  Click = 'Sprig_Click',
  Event = 'Sprig_TrackEvent',
  PageView = 'Sprig_PageView',
  SurveyShown = 'Sprig_ShowSurvey',
  SurveySubmitted = 'Sprig_SubmitSurvey',
  Noop = 'Sprig_Noop',
  Meta = 'Sprig_Meta',
  Touch = 'Sprig_Touch',
  Keystroke = 'Sprig_Keystroke',
  BackForward = 'Sprig_BackForward',
  Refresh = 'Sprig_Refresh',
  Scroll = 'Sprig_Scroll',
  RageClick = 'Sprig_RageClick',
  DeadClick = 'Sprig_DeadClick',
  ErrorClick = 'Sprig_ErrorClick',
}

export enum ReplayerType {
  replays = 'replays',
  heatmaps = 'heatmaps',
  backdrops = 'backdrops',
}

export interface ReplayUrlInfo {
  url: string;
  startTimestamp: number;
  endTimestamp: number;
}

export const TOP_LEVEL_HTML_STRING = '//HTML[1]';

export const MobilePrivacyLevels = {
  [PrivacySettings.Basic]: {
    maskSecureText: true,
    maskInputFields: false,
    maskText: false,
    maskImages: false,
    hideTouches: false,
  },
  [PrivacySettings.Moderate]: {
    maskSecureText: true,
    maskInputFields: true,
    maskText: false,
    maskImages: false,
    hideTouches: false,
  },
  [PrivacySettings.Strict]: {
    maskSecureText: true,
    maskInputFields: true,
    maskText: true,
    maskImages: true,
    hideTouches: true,
  },
};

const pIIInputFields = [
  'input[type="password"]',
  'input[autocomplete="off"]',
  'input[autocomplete="new-password"]',
  'input[name*="ssn"]',
  'input[name*="credit"]',
  'input[name*="card"]',
  'input[name*="security"]',
  'input[data-sensitive="true"]',
  'input[type="email"]',
  'input[type="tel"]',
];
const inputFields = ['input'];
const canvasFields = ['canvas'];
const mapFields = ['map', 'img[src*="maps.googleapis.com"]', 'canvas'];
const imageFields = ['img', 'svg'];
const videoFields = ['video'];

export interface WebPrivacySettings {
  blockSelector: string;
  maskTextSelector?: string;
  hideInteractions: boolean;
  showCssSelectors?: string;
  collectFonts?: boolean;
}

export const WebPrivacyLevels: Record<PrivacySettings, Omit<WebPrivacySettings, 'showCssSelectors'>> = {
  [PrivacySettings.Basic]: {
    blockSelector: [...pIIInputFields].join(','),
    hideInteractions: false,
  },
  [PrivacySettings.Moderate]: {
    blockSelector: [...pIIInputFields, ...inputFields, ...canvasFields, ...mapFields].join(','),
    hideInteractions: false,
  },
  [PrivacySettings.Strict]: {
    blockSelector: [
      ...pIIInputFields,
      ...inputFields,
      ...canvasFields,
      ...mapFields,
      ...imageFields,
      ...videoFields,
    ].join(','),
    maskTextSelector: '*',
    hideInteractions: true,
  },
} as const;

export interface PageVisit {
  pageId: string;
  pageTitle: string;
  startTime: number;
  endTime: number;
}
