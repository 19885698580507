export enum STUDY_TYPES {
  SESSION_REPLAY = 'sessionReplay',
  HEATMAP = 'heatmap',
  IN_PRODUCT_SURVEY = 'inProductSurvey',
  FEEDBACK_BUTTON = 'feedbackButton',
  CONCEPT_TEST = 'conceptTest',
  SHAREABLE_STUDY = 'shareableStudy',
  ALWAYS_ON_REPLAY = 'alwaysOnReplay',
}

export const StudyTypeDisplayOrder = [
  STUDY_TYPES.IN_PRODUCT_SURVEY,
  STUDY_TYPES.FEEDBACK_BUTTON,
  STUDY_TYPES.ALWAYS_ON_REPLAY,
  STUDY_TYPES.SESSION_REPLAY,
  STUDY_TYPES.HEATMAP,
  STUDY_TYPES.CONCEPT_TEST,
  STUDY_TYPES.SHAREABLE_STUDY,
];

export enum PLATFORM_TYPES {
  WEB = 'web',
  EMAIL = 'email',
  MOBILE = 'mobile',
  LINK = 'link',
}

export enum FeedbackButtonPlacement {
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  CenterLeft = 'center-left',
  CenterRight = 'center-right',
}

export enum FeedbackButtonTheme {
  Dark = 'dark',
  Light = 'light',
}

export enum FeedbackDesktopDisplay {
  CenterModal = 'center-modal',
  Slider = 'slider',
}

export interface StudyConfigs {
  buttonTheme?: FeedbackButtonTheme;
  desktopButtonPlacement?: FeedbackButtonPlacement;
  desktopDisplay?: FeedbackDesktopDisplay;
  hideOnMobile?: boolean;
  mobileButtonPlacement?: FeedbackButtonPlacement;
}

export const DEFAULT_FEEDBACK_BUTTON_CONFIGS: StudyConfigs = {
  buttonTheme: FeedbackButtonTheme.Light,
  desktopButtonPlacement: FeedbackButtonPlacement.CenterRight,
  desktopDisplay: FeedbackDesktopDisplay.Slider,
  hideOnMobile: false,
  mobileButtonPlacement: FeedbackButtonPlacement.CenterRight,
};

export enum ReplayDurationType {
  Before = 'before',
  After = 'after',
  BeforeAndAfter = 'beforeAndAfter',
}

export interface SurveyProperties {
  conceptTesting?: { prototypeUrl?: string }[];
  emailSubject?: string;
  feedbackLabel?: string;
  hasNoPlatform?: boolean;
  hasViewedAudiencePage?: boolean;
  hasViewedDesignPage?: boolean;
  hasRequestedReplayAnalysisThemeGeneration?: boolean;
  introText?: string;
  isUserInterviews?: boolean;
  matrixColumn?: { label: string; value: string }[];
  previewDomain?: string | null;
  previewQrCode?: string;
  previewKey?: string;
  replyAddress?: string;
  replayDurationSeconds?: number;
  replayDurationType?: ReplayDurationType;
  senderName?: string;
  studyType: STUDY_TYPES;
  translationLanguages?: string[];
}

export const ALLOWED_REPLAY_STUDY_TYPES = new Set([
  STUDY_TYPES.IN_PRODUCT_SURVEY,
  STUDY_TYPES.FEEDBACK_BUTTON,
  STUDY_TYPES.HEATMAP,
  STUDY_TYPES.SESSION_REPLAY,
]);
