import { useEffect } from 'react';

import { colors } from '../styles/colors';

import { useQueryParam } from './useQueryParam';

export const fadeOutAnimation = `
.fadeOut {
  @keyframes fadeOut {
    0% {
      background-color: ${colors.semantic.headsUpBackground};
    }
    100% {
      background-color: unset;
    }
  }
}
`;

export const useScrollToElement = ({
  ref,
  queryParamKey,
  comparator,
  topOffset = 0,
  cb,
}: {
  ref: React.RefObject<HTMLElement> | null;
  queryParamKey: string;
  comparator: number; // currently only supports number comparison
  topOffset?: number;
  cb?: () => void;
}) => {
  const [paramValue] = useQueryParam(queryParamKey);
  useEffect(() => {
    if (ref?.current && paramValue === comparator) {
      ref.current.style.scrollMarginTop = `${topOffset}px`;
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      ref.current.style.animation = `fadeOut 3s`;
      if (cb) cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, paramValue, comparator, topOffset]);
};
