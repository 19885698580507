// COMPONENT SHOULD BE REMADE, AVOID MODIFICATION
import React, { useMemo } from 'react';

import styled from '@emotion/styled';
import pluralize from 'pluralize';
import ReactSelect, {
  GroupBase,
  OptionProps,
  PlaceholderProps,
  Props,
  SelectInstance,
  StylesConfig,
  ValueContainerProps,
  components as reactSelectComponents,
} from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import { colors, size } from '../../../styles';
import { Icon, IconProps } from '../../Icon';
import { Checkbox } from '../Checkbox';
import { Label } from '../Label';

// This module-level counter is used to generate unique ids for pointing label
// to select input
let selectCount = 0;

export interface OptionType<T> {
  label: string;
  value: T;
  isDisabled?: boolean;
  options?: OptionType<T>[];
}

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    option?: Option;
    isMulti: IsMulti;
    group?: Group;
    context: Record<string, never>;
  }
}

type SelectProps<V, M extends boolean = false> = {
  autoWidth?: boolean;
  autoFocus?: boolean;
  components?: Partial<SelectComponents<unknown, M, GroupBase<unknown>>>;
  disabled?: boolean;
  error?: boolean;
  hasZIndex?: boolean;
  isMulti?: M;
  disableSearch?: boolean;
  label?: string;
  menuAtRoot?: boolean;
  onChange?: M extends false ? (value: V) => void : (value: OptionType<V>[]) => void;
  options: OptionType<V>[];
  showDropdownIndicator?: boolean;
  smallLabel?: boolean;
  value?: null | (M extends false ? V : OptionType<V>[]);
  restOfStyles?: StylesConfig<OptionType<V>>;
  selectAll?: boolean;
  selectAllLabel?: string;
  /**
   * Custom props to pass to the select components
   */
  context?: Record<string, unknown>;
  selectRef?: React.MutableRefObject<SelectInstance | null> | React.RefCallback<unknown>;
  styles?: StylesConfig<OptionType<V>>;
  valueRenderer?: string | null;
} & Pick<
  Props<OptionType<V>, M>,
  | 'className'
  | 'defaultInputValue'
  | 'defaultMenuIsOpen'
  | 'defaultValue'
  | 'filterOption'
  | 'formatOptionLabel'
  | 'inputValue'
  | 'isClearable'
  | 'isLoading'
  | 'isSearchable'
  | 'menuIsOpen'
  | 'name'
  | 'onInputChange'
  | 'onMenuOpen'
  | 'onMenuScrollToBottom'
  | 'openMenuOnFocus'
  | 'placeholder'
  | 'styles'
  | 'onBlur'
  | 'onFocus'
>;

export const Select = <V, M extends boolean = false>({
  autoWidth = true,
  className,
  components,
  defaultValue,
  disabled = false,
  error,
  hasZIndex = true,
  isClearable = false,
  isMulti,
  disableSearch = false,
  label,
  menuAtRoot = true,
  onChange = () => {},
  options,
  selectAll,
  selectAllLabel = 'Select all',
  selectRef,
  showDropdownIndicator = true,
  smallLabel,
  styles = {},
  value,
  ...forSelect
}: SelectProps<V, M>) => {
  const inputId = useMemo(() => `select-${selectCount++}`, []);

  const mergedStyles: StylesConfig<OptionType<V>> = {
    ...styles,
    container: getContainerStyle({ hasZIndex, styles }),
    control: getControlStyle({ error, label, styles }),
    dropdownIndicator: getDropdownIndicatorStyle({ styles }),
    indicatorsContainer: getIndicatorContainerStyles({ styles }),
    indicatorSeparator: getIndicatorSeparatorStyle({ styles }),
    menu: getMenuStyle({ styles, autoWidth }),
    menuPortal: getMenuPortalStyle({ styles }),
    option: getOptionStyle({ styles }),
    placeholder: getPlaceHolderStyle({ styles }),
    singleValue: getSingleValueStyle({ styles, disabled }),
    valueContainer: getValueContainerStyle({ styles }),
  };

  const multiComponents = useMemo(() => (isMulti ? MULTI_COMPONENTS : {}), [isMulti]);

  const mergedComponents = useMemo(
    () => ({
      Option,
      ...(showDropdownIndicator
        ? {
            DropdownIndicator,
          }
        : {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }),
      ...components,
      ...multiComponents,
    }),
    [components, multiComponents, showDropdownIndicator]
  );

  let newOptions = options.map((o) => ({ ...o, isDisabled: o.isDisabled })).slice();
  if (isMulti && selectAll && Array.isArray(value)) {
    newOptions = [
      {
        label: selectAllLabel,
        // @ts-expect-error TODO: improve type
        value: 'all',
      },
      ...newOptions,
    ];
  }
  const reactSelect = (
    <ReactSelect
      isMulti={isMulti}
      inputId={inputId}
      menuPortalTarget={menuAtRoot ? document.body : null}
      options={newOptions}
      defaultValue={defaultValue}
      onChange={(selected) =>
        handleOptionSelected({
          selected,
          isMulti: !!isMulti,
          options: newOptions,
          originalOptions: options,
          selectAll: !!selectAll,
          onChange,
        })
      }
      isClearable={isClearable}
      isDisabled={disabled}
      hideSelectedOptions={false}
      styles={mergedStyles}
      className={className}
      menuPosition="fixed"
      // @ts-expect-error TODO: improve type
      components={mergedComponents}
      // @ts-expect-error TODO: improve type
      value={isMulti ? value : getOptionByValue(options, value)}
      controlShouldRenderValue={!isMulti}
      isSearchable={!isMulti && !disableSearch}
      closeMenuOnSelect={!isMulti}
      // @ts-expect-error TODO: improve type
      ref={selectRef}
      {...forSelect}
    />
  );

  return label ? (
    <SelectWithLabelContainer>
      <Label htmlFor={inputId} small={smallLabel}>
        {label}
      </Label>
      {reactSelect}
    </SelectWithLabelContainer>
  ) : (
    reactSelect
  );
};

const handleOptionSelected = <V,>({
  isMulti,
  onChange,
  options, //Every option in the dropdown including the 'all' option if present
  originalOptions, //The options without a possible 'all' option
  selectAll,
  selected, //Newly selected options
}: {
  isMulti: boolean;
  onChange: (value: V & OptionType<V>[]) => void;
  options: OptionType<V>[];
  originalOptions: OptionType<V>[];
  selectAll: boolean;
  selected: unknown;
}) => {
  if (selectAll && isMulti && Array.isArray(selected)) {
    const hasAll = selected.some((option) => option.value === 'all');
    const newSelected = selected.filter((option) => option.value !== 'all');

    if (hasAll) {
      if (selected.length === options.length) {
        // @ts-expect-error TODO: improve type
        return onChange([]);
      }
      // @ts-expect-error TODO: improve type
      return onChange(originalOptions);
    }

    // @ts-expect-error TODO: improve type
    onChange(newSelected);
  } else {
    // @ts-expect-error TODO: improve type
    onChange(isMulti ? selected : selected?.value);
  }
};

const SelectWithLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// This is necessary for backwards-compatibility with the old Polaris select
// implementations React-select expects the value prop to be label/value option
// objects.
export const getOptionByValue = <V,>(options: OptionType<V>[], value: V): OptionType<V> | null => {
  for (const option of options) {
    if (option.options) {
      const found = getOptionByValue(option.options, value);
      if (found) return found;
    }
    if (option.value === value) return option;
  }
  return null;
};

const getZIndexStyles = (hasZIndex: boolean, state: { isSelected: boolean }) => {
  if (!hasZIndex) return undefined;
  return state.isSelected ? 500 : 21;
};

export const Option = (props: OptionProps) => {
  let isSelected = props.isSelected;
  // @ts-expect-error TODO: improve type
  if (props.isMulti && props.value === 'all') {
    isSelected = props.options.length - 1 === props.getValue().length;
  }
  return (
    <reactSelectComponents.Option {...props} innerProps={{ ...props.innerProps, role: 'option' }}>
      {props.selectProps.isMulti && <StyledCheckbox readOnly checked={isSelected} />}
      {props.children}
    </reactSelectComponents.Option>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

export const DropdownIndicator = (props: { className?: string } & Omit<IconProps, 'src'>) => {
  return <StyledIcon {...props} src="caret-down" />;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<{ label: string; value: never }>) => {
  const options = props.options;
  // @ts-expect-error TODO: improve type
  const hasSelectAll = options.some((option) => option.value === 'all');

  const values = props.getValue();
  const length = values.length;
  // @ts-expect-error TODO: improve type
  const renderValueText = props.selectProps.valueRenderer;

  const renderValue = () => {
    if (renderValueText) return renderValueText;
    if (hasSelectAll && length === options.length - 1) return options[0].label;
    if (length > 1) return `${length} ${pluralize('items', length)} selected`;
    if (length === 1) return values[0].label;
    return null;
  };

  return (
    <reactSelectComponents.ValueContainer {...props}>
      <ValueContainerHolder>{renderValue()}</ValueContainerHolder>
      {children}
    </reactSelectComponents.ValueContainer>
  );
};

const StyledIcon = styled(Icon)`
  color: ${colors.typography.primary};
  display: inline-block;
  height: 16px;
  line-height: 1;
  margin: 4px;
  width: 16px;
`;

const Placeholder = (props: PlaceholderProps) => {
  const value = props.getValue();
  if (value?.length) return null;
  return <reactSelectComponents.Placeholder {...props} />;
};

const MULTI_COMPONENTS = {
  ValueContainer,
  Placeholder,
  ClearIndicator: null,
};

const ValueContainerHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const getContainerStyle = <V,>({
  styles,
  hasZIndex,
}: {
  hasZIndex: boolean;
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['container'] => {
  const { container } = styles;
  return (base, state) => ({
    ...base,
    borderColor: 'transparent',
    boxShadow: 'none',
    // @ts-expect-error TODO: improve type
    zIndex: getZIndexStyles(hasZIndex, state),
    ...(container ? container(base, state) : {}),
  });
};

const getControlStyle = <V,>({
  error,
  label,
  styles,
}: {
  error?: boolean;
  label?: string;
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['control'] => {
  const { control } = styles;
  return (base, state) => ({
    ...base,
    background: colors.background.primary,
    borderColor: error ? colors.semantic.negativeBorder : colors.border.light,
    borderRadius: size(1),
    boxShadow: 'none',
    flex: label ? '1' : 'auto',
    '&:hover, &:active': {
      cursor: 'pointer',
      borderColor: error ? colors.semantic.negativeBorder : colors.border.medium,
    },
    flexWrap: 'nowrap',
    minHeight: '40px',
    ...(control ? control(base, state) : {}),
  });
};

const getDropdownIndicatorStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['dropdownIndicator'] => {
  const { dropdownIndicator } = styles;
  return (base, state) => ({
    ...base,
    marginRight: size(1),
    ...(dropdownIndicator ? dropdownIndicator(base, state) : {}),
  });
};

const getIndicatorSeparatorStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['indicatorSeparator'] => {
  const { indicatorSeparator } = styles;
  return (base, state) => ({
    ...base,
    display: 'none',
    ...(indicatorSeparator ? indicatorSeparator(base, state) : {}),
  });
};

const getMenuStyle = <V,>({
  autoWidth,
  styles,
}: {
  autoWidth: boolean;
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['menu'] => {
  const { menu } = styles;
  return (base, state) => ({
    ...base,
    width: autoWidth ? 'max-content' : '100%',
    zIndex: '500',
    ...(menu ? menu(base, state) : {}),
  });
};

const getMenuPortalStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['menuPortal'] => {
  const { menuPortal } = styles;
  return (base, state) => ({
    ...base,
    zIndex: '500',
    ...(menuPortal ? menuPortal(base, state) : {}),
  });
};

const getOptionStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['option'] => {
  const { option } = styles;

  return (base, state) => {
    let background = 'transparent';
    if (state.isSelected) {
      background = colors.background.selected;
    } else if (state.isFocused) {
      background = colors.background.secondary;
    }

    return {
      ...base,
      alignItems: 'center',
      cursor: 'pointer',
      color: colors.typography.primary,
      background,
      whiteSpace: 'pre-wrap',
      '&:hover': {
        background: colors.background.secondary,
      },

      ...(option ? option(base, state) : {}),
    };
  };
};

const getPlaceHolderStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['placeholder'] => {
  const { placeholder } = styles;
  return (base, state) => ({
    ...base,
    color: colors.typography.disabled,
    ...(placeholder ? placeholder(base, state) : {}),
  });
};

const getSingleValueStyle = <V,>({
  styles,
  disabled,
}: {
  disabled: boolean;
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['singleValue'] => {
  const { singleValue } = styles;
  return (base, state) => ({
    ...base,
    color: disabled ? colors.navy[500] : colors.common.black,
    marginLeft: 0,
    marginRight: 0,
    ...(singleValue ? singleValue(base, state) : {}),
  });
};

const getValueContainerStyle = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['valueContainer'] => {
  const { valueContainer } = styles;
  return (base, state) => ({
    ...base,
    color: colors.navy[1000],
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: size(2),
    ...(valueContainer ? valueContainer(base, state) : {}),
  });
};

const getIndicatorContainerStyles = <V,>({
  styles,
}: {
  styles: StylesConfig<OptionType<V>>;
}): StylesConfig<OptionType<V>>['indicatorsContainer'] => {
  const { indicatorsContainer } = styles;
  return (base, state) => ({
    ...base,
    marginRight: size(1),
    ...(indicatorsContainer ? indicatorsContainer(base, state) : {}),
  });
};
