import React from 'react';

import styled from '@emotion/styled';

import { size, colors, typography } from '../../styles';
import { Icon } from '../Icon';
import { Popup } from '../Popup';

type PremiumFeatureBadgeProps = {
  startIcon?: boolean;
  tooltip?: React.ReactNode;
};

const PremiumBadge = ({ startIcon = false }: { startIcon?: boolean }) => (
  <Badge>{startIcon && <Icon src="upgrade" />}Premium feature</Badge>
);

export const PremiumFeatureBadge = ({ startIcon = false, tooltip }: PremiumFeatureBadgeProps) => {
  return tooltip ? (
    <StyledPopup on="hover" role="tooltip" placement="top" trigger={<PremiumBadge startIcon={startIcon} />}>
      <Message>{tooltip}</Message>
    </StyledPopup>
  ) : (
    <PremiumBadge startIcon={startIcon} />
  );
};

const StyledPopup = styled(Popup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${size(2)};
  gap: ${size(2)};
  width: 220px;
`;

const Message = styled.div`
  font-style: normal;
  font-weight: 500;
  ${typography.body};
`;

const Badge = styled.div`
  ${typography.captionBold}
  align-items: flex-end;
  background-color: ${colors.indigo[100]};
  border-radius: 12px;
  color: ${colors.indigo[1000]};
  display: flex;
  flex-direction: row;
  gap: ${size(0.5)};
  height: ${size(3)};
  justify-content: center;
  letter-spacing: -0.1px;
  margin-bottom: ${size(2)};
  padding: 4px 8px;
  text-align: center;
  width: fit-content;
`;
