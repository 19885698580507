import React from 'react';

import styled from '@emotion/styled';

import { colors, size } from '../../styles';

const AuthParagraph = styled.div`
  margin: ${size(1.25)};
  color: ${colors.common.white};
`;

export const AuthMessage = () => (
  <>
    <AuthParagraph>We&apos;re having authentication problems.</AuthParagraph>
    <AuthParagraph>Close this message to go back to the login page.</AuthParagraph>
  </>
);
